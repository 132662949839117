<template>
  <div class="admin-main-section">
    <div class="container">
      <div class="row">
        <!-- main content -->
        <div class="col-12 admin-main-section-header">
          <h3>Komentari</h3>
        </div>
        <div v-for="(comment, index) in comments" :key="index" class="col-12 komentar-div">
          <div class="row no-gutters">
            <div class="col-12 komentar-header">
              <div class="komentar-header-left">
                <h4 class="komentar-username">{{ comment.vendor.name }}</h4>
                <div class="mt-1">
                  <span
                    :class="
                      comment.rating >= 1 ? 'fa fa-star checked' : 'fa fa-star'
                    "
                  ></span>
                  <span
                    :class="
                      comment.rating >= 2 ? 'fa fa-star checked' : 'fa fa-star'
                    "
                  ></span>
                  <span
                    :class="
                      comment.rating >= 3 ? 'fa fa-star checked' : 'fa fa-star'
                    "
                  ></span>
                  <span
                    :class="
                      comment.rating >= 4 ? 'fa fa-star checked' : 'fa fa-star'
                    "
                  ></span>
                  <span
                    :class="
                      comment.rating == 5 ? 'fa fa-star checked' : 'fa fa-star'
                    "
                  ></span>
                </div>
              </div>
              <div class="komentar-header-right">
                <small class="komentar-date">{{
                    new Date(comment.created_at).toDateString()
                }}</small>
              </div>
            </div>
            <div class="col-12 komentar-body">
              <p class="komentar-tekst">
                {{ comment.text }}
              </p>
            </div>
          </div>
          <div v-if="comment.response.text !== ''" class="col-12 komentar-div response">
            <div class="row no-gutters">
              <div class="col-12 komentar-header">
                <div class="komentar-header-left">
                  <h4 class="komentar-username">Odgovor:</h4>
                </div>
                <div class="komentar-header-right">
                  <small class="komentar-date">{{
                    new Date(comment.response.created_at).toDateString()
                  }}</small>
                </div>
              </div>
              <div class="col-12 komentar-body">
                <p class="komentar-tekst">{{ comment.response.text }}</p>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Komentari",
  components: {},
  data() {
			return {
				comments: [
          {
            vendor: {
              name: "Davor"
            },
            rating: 4,
            created_at: "2021-12-30",
            text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
            edit: false,
            response: {
              created_at: "2021-12-31",
              text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            }
          },
          {
            vendor: {
              name: "Jasna"
            },
            rating: 3,
            created_at: "2021-12-30",
            text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
            edit: false,
            response: {
              created_at: "",
              text:"",
            }
          },
        ],
			}
		},
};
</script>

<style scoped>

.komentar-div {
  padding: 2rem 1rem;
	box-shadow: 0px 2.828px 5px 0 #00000033;
}

.komentar-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px !important;
}

.komentar-header-right {
	margin-left: auto;
}

h4 {
  padding: 0;
  margin: 0;
}

.komentar-btns {
	text-align: right;
}

.fa {
  color: lightgrey;
}
.checked {
	color: #ffd800 !important;
}

.response {
  background: #eef7ff;
  padding: 1rem;
  width: 95%;
  margin-left: 5%;
  border-radius: 5px;
}

</style>
