<template>
  <div class="admin-main-section">
    <div class="container">
      <div class="row">
        <!-- main content -->
        <div class="col-12 admin-main-section-header">
          <h3>Polaznici</h3>
        </div>
        <div class="col-12 mb-4">
            <div class="row no-gutters action-bar">
                <div class="col-7">
                    <form class="d-flex">
                        <input class="form-control borderless" type="search" placeholder="Traži..." aria-label="Search">
                        <button class="btn btn-search" type="submit"><span class="fas fa-search"></span></button>
                    </form>
                </div>
                <div class="col-4">
                    <div @click="filterToggle" class="filter-btn"><h6><span class="fas fa-filter mr-2"></span> Filtriraj</h6></div>
                </div>
                <div class="col-12">
                    <div v-if="showFilter" class="col-12 box box--top">
                        <div class="row">
                            <div class="col-6">
                                <label for="status">Status:</label>
                                <select class="custom-select custom-slct" id="status">
                                    <option selected>Svi</option>
                                    <option value="1">Plaćeno</option>
                                    <option value="2">Duguje</option>
                                </select>
                            </div>
                            <div class="col-6">
                                <label for="poredak">Poredak:</label>
                                <select class="custom-select custom-slct" id="poredak">
                                    <option selected value="1">A-Z</option>
                                    <option value="2">Datum</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
          v-for="(trainee, index) in trainees"
          :key="index"
          class="col-12 polaznik-div"
          :class="trainee.paid ? 'borderGreen' : 'borderRed'"
        >
          <div class="row no-gutters">
            <div class="col-12 polaznik-header">
              <div class="polaznik-header-left">
                <h5 class="polaznik-username">{{ trainee.vendor.name }}</h5>
                <div class="mt-1 pl-2">
                  <h6>
                    <a href="callto:0987654321"
                      ><span class="fas fa-phone mr-3 plava"></span
                    ></a>
                    <a href="mailto:example@gmail.com"
                      ><span class="far fa-envelope mr-3 plava"></span
                    ></a>
                    <a href="#"
                      ><span class="far fa-calendar-alt plava"></span
                    ></a>
                  </h6>
                </div>
              </div>
              <div class="polaznik-header-right">
                <button class="btn btn-circle mr-1">
                  <span class="fas fa-ellipsis-h"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminVendorPolaznici",
  components: {},
  data() {
    return {
      trainees: [
        {
          vendor: {
            name: "Davor Mikulić",
          },
          rating: 4,
          created_at: "2021-12-30",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
          edit: false,
          response: {
            created_at: "2021-12-31",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          paid: true,
        },
        {
          vendor: {
            name: "Jasna Kolimbatović",
          },
          rating: 3,
          created_at: "2021-12-30",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
          edit: false,
          response: {
            created_at: "",
            text: "",
          },
          paid: true,
        },
        {
          vendor: {
            name: "Mate Matić",
          },
          rating: 3,
          created_at: "2021-12-30",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
          edit: false,
          response: {
            created_at: "",
            text: "",
          },
          paid: false,
        },
      ],
      status: 1,
      showFilter: false
    };
  },
  methods: {
      filterToggle (){
          this.showFilter = !this.showFilter
      }
  }
};
</script>

<style scoped>
.polaznik-div {
  background: #fff;
  transition: all 0.3s ease;
  box-shadow: 0px 2.828px 5px 0 #00000033;
  margin-bottom: 0.2rem;
}

.action-bar {
  align-items: center;
  justify-content: space-between;
}

.action-bar h6{
    margin: 0;
    text-align: center;
}

.borderGreen {
  border-left: 0.5rem solid rgb(172, 255, 179);
}

.borderRed {
  border-left: 0.5rem solid rgb(255, 165, 165);
}

.polaznik-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.polaznik-header-right {
  margin-left: auto;
  margin-bottom: 0.5rem;
}

h5 {
  font-weight: 500;
}

h6 {
  font-weight: 300;
}

.polaznik-btns {
  text-align: right;
}

.fa {
  color: lightgrey;
}
.checked {
  color: #ffd800 !important;
}

.response {
  background: #eef7ff;
  padding: 1rem;
  width: 95%;
  margin-left: 5%;
  border-radius: 5px;
}

.btn-circle {
  width: 2rem !important;
  height: 2rem !important;
  padding: 6px 0px !important;
  border-radius: 1rem !important;
  font-size: 0.7rem !important;
  text-align: center !important;
  background: #eee;
}

.btn-search {
    background: #eee;
}

.filter-btn {
  padding: .6rem 0;
  background-color: #1D9AE6;
  color: white;
}

.filter-btn:hover {
  cursor: pointer;
}

.box {
  width: 100%;
  border: 1px solid #1D9AE6;
  border-radius: 5px;
  position: relative;
  background-color: #1D9AE6;
  color: white;
  transition: all 0.3s ease-in;
  margin-top: 1rem;
  padding: .5rem 1rem 1rem 1rem;
}

.box--top::after {
  content: "";
  position: absolute;
  transition: all 1s ease-in;
  top: -20px;
  left: calc(85%);
  border-top: 10px solid transparent;
  border-bottom: 10px solid #1D9AE6;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.custom-slct {
    font-size:.7rem;
    text-indent: 0px;
    color: black;
    border-radius: 5px;
}

.box--top label {
    margin-bottom: 0rem;
    font-size: .65rem;
}

</style>
