<template>
  <div id="brojke" class="jumbotron paral paralsec">
    <div class="container">
      <div class="row text-center">
        <div class="col-md-3">
            <h1 class="countup">{{ brojke.gradovi }}</h1>
            <h5>Gradovi</h5>
        </div>
        <div class="col-md-3">
            <h1 class="countup">{{ brojke.sportovi }}</h1>
            <h5>Sportovi</h5>
        </div>
        <div class="col-md-3">
            <h1 class="countup">{{ brojke.treneri }}</h1>
            <h5>Treneri</h5>
        </div>
        <div class="col-md-3">
            <h1 class="countup">{{ brojke.korisnici }} +</h1>
            <h5>Korisnici</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Brojke",
  data() {
    return {
      brojke: {
        gradovi: 1,
        sportovi: 5,
        treneri: 40,
        korisnici: 1000,
      },
      offsetTop: 0,
      visible: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll);
  },
  watch: {
    // kad god se mijenja offset zovi callback funkciju 
    // da ona provjeri jeli vrime za animaciju
    offsetTop(val) {
      this.callbackFunc(val);
    },
  },
  methods: {
    onScroll() {
      // kad se skrola mjeri koliko je skrolano
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
    },
    isElementInViewport(el) {
      // jeli elemenet vidljiv
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    animateCountUp(el) {
      // metoda s neta za animaciju brojaca
      const animationDuration = 2000;
      const frameDuration = 1000 / 60;
      const totalFrames = Math.round( animationDuration / frameDuration );
      const easeOutQuad = t => t * ( 2 - t );

      let frame = 0;
      const countTo = parseInt(el.innerHTML, 10);
      const counter = setInterval(() => {
        frame++;
        const progress = easeOutQuad(frame / totalFrames);
        const currentCount = Math.round(countTo * progress);
        if (parseInt(el.innerHTML, 10) !== currentCount) {
          el.innerHTML = currentCount;
        }
        if (frame === totalFrames) {
          clearInterval(counter);
        }
      }, frameDuration);
    },
    callbackFunc() {
      // ako je element vidljiv pokreni animaciju i sve ostalo zaustavi
      let item = document.getElementById("brojke");
      const countupEls = document.querySelectorAll(".countup");
      if (this.isElementInViewport(item) && !this.visible) {
        countupEls.forEach(this.animateCountUp);
        this.visible = true;
        window.removeEventListener("scroll", this.onScroll);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
