<template>
  <ProfilMain />
</template>

<script>
import ProfilMain from "@/components/profil/ProfilMain.vue";

export default {
  name: "INstruktor",
  components: {
    ProfilMain
  }
};
</script>

<style scoped lang="scss">
</style>
