<template>
  <div class="profil">
    <div class="container">
      <div class="row">
        <!-- main -->
        <div class="col-12 col-lg-8 mb-3">
          <div v-if="instruktor" class="profil-main container py-4">
            <div class="row">
              <!-- ukratko -->
              <div class="col-12 mb-3">
                <div class="profile-section">
                  <div class="row no-gutters">
                    <!-- slika -->
                    <div class="col-4">
                      <div
                        :style="{
                          backgroundImage:
                            'url(' +
                            require('@/assets/instruktori/' + instruktor.slika + '') +
                            ')',
                        }"
                        class="img-wrapper"
                      ></div>
                    </div>
                    <!-- podaci -->
                    <div class="col-8">
                      <div class="container">
                        <div class="row">
                          <div class="col-12">
                            <h2 class="ime">{{ instruktor.ime }}</h2>
                          </div>
                          <div class="col-12">
                            <div class="stars">
                              <p>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star"></span>
                                <span class="ml-1">
                                  <b>{{ instruktor.ocjena }}</b>
                                  ({{ instruktor.broj_ocjena }} ocjena)
                                </span>
                              </p>
                            </div>
                          </div>
                          <div class="col-12 col-md-6">
                            <p>
                              <i class="fa fa-user-graduate mr-2"></i>
                              {{ instruktor.zvanje }}
                            </p>
                          </div>
                          <div class="col-12 col-md-6">
                            <p>
                              <i class="fa fa-tag mr-2"></i>
                              {{ instruktor.cijena }} kn/mj
                            </p>
                          </div>
                          <div class="col-12 col-md-6">
                            <p>
                              <i class="fa fa-users mr-2"></i>
                              {{ instruktor.studenti }} polaznika
                            </p>
                          </div>
                          <div class="col-12 col-md-6">
                            <p>
                              <i class="fa fa-hourglass-half mr-2"></i>
                              {{ instruktor.sati }} treninga
                            </p>
                          </div>
                          <div class="col-12 mt-2">
                            <span v-for="(predmet, index) in instruktor.predmeti" :key="index" class="badge badge-predmet">{{predmet.ime}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- o meni -->
              <div class="col-12 mb-3">
                <div class="profile-section">
                  <div class="row">
                    <div class="col-12">
                      <h5>O meni</h5>
                      <p class="text-justify">{{ instruktor.opis }}</p>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <!-- o treninzima -->
              <div class="col-12 mb-3">
                <div class="profile-section">
                  <div class="row">
                    <div class="col-12">
                      <h5>O treningu</h5>
                      <p class="text-justify">{{ instruktor.o_instrukcijama }}</p>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <!-- komentari -->
              <div class="col-12 mb-3">
                <div class="profile-section">
                  <div class="row">
                    <div class="col-12">
                      <h5>Ocjene & Komentari</h5>
                    </div>
                    <div class="col-12">
                      <div class="profil-ocjena">
                        <div class="row">
                          <div class="col-6">
                            <div
                              class="row h-100 justify-content-center align-items-center"
                            >
                              <div class="ocjena">
                                <h1 class="">{{ instruktor.ocjena }}</h1>
                                <p class="">{{ instruktor.broj_ocjena }} ocjena</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div
                              class="row h-100 justify-content-center align-items-center"
                            >
                              <div class="stars">
                                <p>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="ml-1">
                                    <small>(41)</small>
                                  </span>
                                </p>
                                <p>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star"></span>
                                  <span class="ml-1">
                                    <small>(3)</small>
                                  </span>
                                </p>
                                <p>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star"></span>
                                  <span class="fa fa-star"></span>
                                  <span class="ml-1">
                                    <small>(0)</small>
                                  </span>
                                </p>
                                <p>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star"></span>
                                  <span class="fa fa-star"></span>
                                  <span class="fa fa-star"></span>
                                  <span class="ml-1">
                                    <small>(0)</small>
                                  </span>
                                </p>
                                <p>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star"></span>
                                  <span class="fa fa-star"></span>
                                  <span class="fa fa-star"></span>
                                  <span class="fa fa-star"></span>
                                  <span class="ml-1">
                                    <small>(2)</small>
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div
                        v-for="(komentar, index) in komentari"
                        :key="index"
                        class="komentar"
                      >
                        <hr />
                        <div class="row">
                          <div class="col-12">
                            <div class="stars mb-2">
                              <span class="fa fa-star checked"></span>
                              <span class="fa fa-star checked"></span>
                              <span class="fa fa-star checked"></span>
                              <span class="fa fa-star checked"></span>
                              <span class="fa fa-star"></span>
                            </div>
                          </div>
                          <div class="col-8">
                            <h6>{{komentar.korisnik.ime}}</h6>
                          </div>
                          <div class="col-4">
                            <p>{{komentarDatum (komentar.created_at)}}</p>
                          </div>
                          <div class="col-12">
                            <p>
                              {{komentar.komentar}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 text-center my-3">
                      <a href="#" class="btn btn-lg btn-outline-primary">Više komentara</a>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-12 col-lg-4">
        <!-- sidebar -->
          <div id="sidebar" class="container py-4 mb-2">
            <h5 class="text-center mb-4">Kontaktiraj trenera</h5>
            <form v-on:submit.prevent v-if="instruktor">
              <input type="hidden" name="formEmail" :value="instruktor.email">

              <div class="form-group">
                <textarea
                  class="form-control"
                  id="formPoruka"
                  name="formPoruka"
                  rows="5"
                >Zainteresiran/a sam za Vaš trening. Molim Vas da mi se javite povratno kako bismo dogovorili termin. Lijep pozdrav!</textarea>
              </div>

              <div class="form-group">
                <label for="formPredmet">Izaberi trening:</label>
                <select class="form-control" id="formPredmet" name="formPredmet">
                  <option
                    v-for="(predmet, index) in instruktor.predmeti"
                    :key="index"
                    :value="predmet.ime"
                    >{{ predmet.ime }}</option
                  >
                </select>
              </div>

              <div class="form-group">
                <label for="formPredmet">Razina treninga:</label>
                <select class="form-control" id="formPredmet" name="formPredmet">
                  <option
                    v-for="(level, index) in levels"
                    :key="index"
                    :value="level"
                    >{{ level }}</option
                  >
                </select>
              </div>

              <a class="btn btn-lg btn-primary btn-block my-3" @click="toggleModal">Pošalji poruku</a>

            </form>
            <p class="text-center"><small>Prosječno vrijeme odgovora: 1,5 sati</small></p>

          </div>
        </div>
      </div>
    </div>

    <div v-if="showModal">
        <div class="modal">
            <div class="modal-body row justify-content-center align-items-center">
                <div class="col-12 col-md-5 d-none d-md-block">
                    <img src="@/assets/featured.jpg" class="img-responsive" alt="">
                </div>
                <div class="col-12 col-md-7">
                    <div class="row">
                        <div class="modal-header col-12">
                            <h4>Prije nego pošaljemo poruku..</h4>
                            <button @click="toggleModal">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                        <div class="modal-content col-12">
                            <p>Ispuni fomrular i registriraj se kako bi te trener mogao kontaktirati.</p>
                            <form class="mt-3">
                                <div class="form-group">
                                    <label for="formIme">* Ime:</label>
                                    <input type="text" class="form-control" id="formIme" name="formIme" aria-describedby="ime">
                                </div>
                                <div class="form-group">
                                    <label for="formEmail">* Email:</label>
                                    <input type="email" class="form-control" id="formEmail" name="formEmail" aria-describedby="email">
                                </div>
                                <div class="form-group">
                                    <label for="formPass">* Lozinka:</label>
                                    <input type="password" class="form-control" id="formPass" name="formPass" aria-describedby="password">
                                </div>
                                <div class="form-group">
                                    <label for="formTel">Kontakt broj:</label>
                                    <input type="number" class="form-control" id="formTel" name="formTel" aria-describedby="telephone">
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="formRegistracija" name="formRegistracija" checked />
                                    <label class="custom-control-label" for="formRegistracija"><small>Zapamti me</small></label>
                                </div>
                                <div class="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" class="custom-control-input" id="formPravila" name="formPravila" />
                                    <label class="custom-control-label" for="formPravila"><small>Prihvaćam pravila i uvjete korištenja stranice</small></label>
                                </div>
                                <button type="submit" class="btn btn-lg btn-primary btn-block mb-3">
                                    Završi rezervaciju
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer col-12">

                </div>
            </div>
        </div>
        <div class="modal-bg">
        </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from "vuex";

export default {
  name: "ProfilMain",
  components: {
    
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.loading,
      instruktor: (state) => state.instruktor,
      komentari: (state) => state.komentari,
      cijene: (state) => state.cijene,
      formatedDate() {
        return moment(this.selectedDate).format('DD/MM/YYYY')
      }
    }),
  },
  data() {
		return {
      showModal: false,
			mail: {
				korisnik_ime: '',
        korisnik_mail: '',
				instruktor_mail: '',
				poruka: '',
				ponude: false
			},
      levels: [
        'početnik',
        'rekreativac',
        'profesionalac',
        'veteran'
      ],
    }
  },
  mounted() {
    const id = this.$route.params.id;
    this.$store.dispatch("getInstruktor", id);
    this.$store.dispatch("getKomentari", id);
    this.$store.dispatch("getCijene", id);
  },
  methods: {
    komentarDatum (datum) {
      return moment(datum).format('DD.MM.YYYY.');
    },
    onDayClick () {
      // zovi api i uzmi termine za taj dan
      // spremi termine u computed valjda
      // pokreni ovo odma pri ucitavanju stranice
    },
    onTimeClick () {

      // stavi to u varijablu za buking data valjda
    },
    toggleModal () {
      this.showModal = !this.showModal;
    },
  }
};
</script>

<style scoped lang="scss">
.profil-main {
  min-height: 200px;
  background: #fff;
  box-shadow: 2.828px 2.828px 15px 0 rgba(0, 0, 0, 0.2);
}

hr {
  margin-top: 0.4rem;
  margin-bottom: 0.2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.img-wrapper {
  overflow: hidden;
  height: 10em;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}

.badge {
  margin: 0.1rem;
  border-radius: 0;
  padding: 0.5rem;
}

.badge-predmet {
  border: 1px solid #1D9AE6;
  color: #1D9AE6;
  font-size: 0.55rem;
}

.badge-predmet:hover {
  border: 1px solid #1D9AE6;
  color: #fff;
  background: #1D9AE6;
  font-size: 0.55rem;
  cursor: pointer;
}

.opis {
  font-size: 0.65rem;
  text-align: justify;
}

i {
  min-width: 20px;
}

.stars {
  margin-bottom: 1rem;
}

.fa {
  color: #1D9AE6;
}

.fa-star {
  color: lightgray;
}

.checked {
  color: rgb(243, 178, 0) !important;
}

.ocjena h1 {
  color: #1D9AE6;
  font-weight: bold;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
}

td {
    font-size: .75rem;
}

#sidebar {
  min-height: 150px;
  background: #ffffff;
  box-shadow: 2.828px 2.828px 15px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;
}

#sidebar .form-control{
  background: transparent;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #1D9AE6;
	border-color: #cccccc;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	background-color: #1D9AE6;
	border-color: #cccccc;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.custom-control-label {
	font-weight: 400;
  font-size: .8rem;
}

.custom-control-label::before ,.custom-control-label::after {
  width:.8rem; 
  height:.8rem;
}

.termini-row {
  padding: .5rem;
  background-color: #fff;
  border: 1px solid;
  border-color: #cbd5e0;
  border-radius: 0.5rem;
}

.termini-box {
  color: #000;
  background: transparent;
  border: 1px solid transparent;
  margin: 5px;
  padding: 5px 0;
  text-align: center;
  font-size: .7rem;
  font-weight: 500;
}

.termini-box:hover {
  cursor: pointer;
  background: #edf2f7;
}

.clicked {
  color: #fff;
  background: #1D9AE6;
  border: 1px solid #1D9AE6;
}

/* modal stilovi */

body {
    overflow: hidden;
}

.modal-bg {
    opacity: .25;
    background-color: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
}

.modal {
    position: fixed;
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 10000;
}

.modal-body {
    position: relative;
    padding: 0;
    margin: 15px;
    margin-top: 10vh;
    background-color: #fff;
    border-radius: 5px;
    max-height: calc(100vh - 30px);
    overflow-y: auto;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 0px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid transparent;
    outline: 0;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    border-top: 0px solid transparent;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
    .modal-body {
        max-width: 500px;
    }
}
@media (min-width: 768px) {
    .modal-body {
        max-width: 700px;
    }
}

@media (min-width: 992px) {
    .modal-body {
        max-width: 900px;
    }
}

@media (min-width: 1200px) {
    .modal-body {
        max-width: 1100px;
    }
}

button {
    appearance: auto;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    background-color: transparent;
    box-sizing: border-box;
    margin: 0em;
    font: 400 24px Arial;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: transparent;
    border-image: initial;
}

</style>
