<template>
  <div class="">
    <h5>Vrsta treninga</h5>
    <ul>
      <li><a class="category" href="#">Grupni trening</a></li>
      <li><a class="category" href="#">Individualni trening</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SidebarCategory",
};
</script>

<style scoped lang="scss">

h5 {
    font-size: .9rem;
    margin-bottom: 5px;
}

li {
    line-height:1.5;
}

.category {
    font-size: .8rem;
    color: #212529;
}

</style>
