<template>
  <div class="admin-main-section">
    <div class="container">
      <div class="row">
        <!-- main content -->
        <div class="col-12 admin-main-section-header">
          <h3>Termini</h3>
        </div>
        <div class="col-12">
          <Calendar
            ref="calendar"
            :attributes="attributes"
            locale="hr"
            is-expanded
            :min-date="today"
            :disabled-dates="disabledDates"
            @dayclick="dayClicked"
          >
          </Calendar>
        </div>
      </div>
    </div>

    <div v-if="showModal">
        <div class="modal">
            <div class="modal-body row justify-content-center align-items-center">
                <div  v-if="modalLvl === 1" class="col-12">
                    <div class="row">
                        <div class="modal-header header-1 col-12 align-items-center">
                            <span class="far fa-check-circle"></span>
                            <h4 class="modal-title">{{ modalTitle }}</h4>
                            <button class="close-button" @click="toggleModal">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                        <div class="modal-content col-12">
                          <ul class="termin-ul">
                            <li><span class="fas fa-calendar"></span>{{ modalDate }}</li>                          
                            <li><span class="fas fa-clock"></span>{{ modalTime }} sati</li>
                            <li><span class="fas fa-dumbbell"></span>{{ modalSport }}</li>
                            <li><span class="fas fa-user"></span>{{ modalTrainer }}</li>
                          </ul>
                          <div class="btn-group">
                            <button @click="changeModalLvl" type="button" class="btn btn-primary mr-1"><span class="fas fa-exchange-alt mr-2"></span>Promijeni Termin</button>
                            <button type="button" class="btn btn-danger"><span class="fas fa-times mr-2"></span>Otkaži</button>
                          </div>
                        </div>
                    </div>
                </div>
                <div  v-if="modalLvl === 2" class="col-12">
                    <div class="row">
                        <div class="modal-header header-2 col-12 align-items-center">
                            <span class="fas fa-calendar"></span>
                            <h4 class="modal-title">{{ modalTitle }}</h4>
                            <button class="close-button" @click="toggleModal">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                        <div class="modal-content col-12">
                          <div class="row no-gutters termini-row">
                            <div v-for="(termin, index) in dostupniTermini" :key="index" class="col-3">
                              <div @click="onTimeClick" class="termini-box">
                                {{ termin }}
                              </div>
                            </div>
                          </div>
                          <div class="btn-group">
                            <button type="button" class="btn btn-primary mr-1"><span class="fas fa-check mr-2"></span>U redu</button>
                            <button type="button" class="btn btn-danger"><span class="fas fa-arrow-left mr-2"></span>Natrag</button>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer col-12 justify-content-center align-items-center">
                  <small>Powered by Figaro</small>
                </div>
            </div>
        </div>
        <div class="modal-bg">
        </div>
    </div>

  </div>
</template>

<script>
import { Calendar } from "v-calendar";

export default {
  name: "Termini",
  components: {
    Calendar,
  },
  data() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    return {
      showModal: false,
      modalLvl: 0,
      modalTitle: "",
      modalDate: "",
      modalTime:"",
      modalSport: "",
      modalTrainer: "",
      today: date,
      disabledDates: [
            new Date(year, month, 9).getTime(),
            new Date(year, month, 16).getTime(),
            new Date(year, month, 23).getTime(),
      ],
      attributes: [
        {
          key: 'confirmed',
          highlight: {
            color: 'green',
            fillMode: 'light',
            contentClass: 'italic',
          },
          dates: [
            new Date(year, month, 3).getTime(),
            new Date(year, month, 5).getTime(),
            new Date(year, month, 7).getTime(),
          ]
        }
      ],
      dostupniTermini : ["09.00", "10.00", "11.00", "12.00", "13.00", "14.00", "15.00", "16.00", ],
      selectedDate: ""
    };
  },
  methods: {
    toggleModal () {
      this.showModal = !this.showModal;
    },
    dayClicked(day){
      const date = day.date.getTime();
      const disabledDates = this.disabledDates;
      const availDate = disabledDates.indexOf(date);

      this.selectedDate = date;

      // ako odabrani datum nije u arrayu disabled datuma
      if (availDate === -1) {

        const approvedDates = this.attributes[0].dates;
        const avDate = approvedDates.indexOf(date);
        this.modalDate= new Date (date).toLocaleDateString('hr-HR')

        // ako odabrani datum nije oznacen kao trening
        if (avDate === -1) {
          this.modalLvl = 2
          this.modalTitle = "Izaberi termin";
        } else {
          this.modalLvl = 1;
          this.modalTitle = "Detalji termina";
          this.modalTime="11.00"
          this.modalSport="crossFit"
          this.modalTrainer="John Doe"
        }

        this.toggleModal ();

      }
    },
    changeModalLvl(){
      this.modalLvl = 2
      this.modalTitle = "Izaberi termin";
    },
    onTimeClick() {
      console.log()
    }
  },
};
</script>

<style scoped>
.admin-main-section {
  background: #fff;
  box-shadow: 2.828px 2.828px 15px 0 rgb(0 0 0 / 20%);
  transition: all 0.3s ease;
  padding: 1rem 0;
}

.carousel__slide--active > .carousel__item {
  font-weight: bold;
  padding: 0.5rem 0.9rem;
  background: tomato;
  color: #fff;
  border-radius: 50%;
}

/* kalendar */

.termin-ul span {
  color: #1D9AE6;
  font-size: .8rem;
  min-width: 1rem;
  text-align: center;
  margin-right: 1rem;
}

.termini-row {
  padding: .5rem;
  background-color: #fff;
  border: 1px solid;
  border-color: #cbd5e0;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}

.termini-box {
  color: #000;
  background: transparent;
  border: 1px solid transparent;
  margin: 5px;
  padding: 5px 0;
  text-align: center;
  font-size: .7rem;
  font-weight: 500;
}

.termini-box:hover {
  cursor: pointer;
  background: #edf2f7;
}

.clicked {
  color: #fff;
  background: #1D9AE6;
  border: 1px solid #1D9AE6;
}

/* modal stilovi */

body {
    overflow: hidden;
}

.modal-bg {
    opacity: .25;
    background-color: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
}

.modal {
    position: fixed;
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 10000;
}

.modal-body {
    position: relative;
    padding: 0;
    margin: 15px;
    margin-top: 10vh;
    background-color: #fff;
    border-radius: 5px;
    max-height: 80vh;
    overflow-y: auto;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 0px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #c6f6d5;
    padding: 1rem;
}

.header-1 {
  background: #c6f6d5;
}

.header-2 {
    background: #c5e1ff;
}

.modal-header span {
    font-size: 1.7rem;
    color: #fff;
}

.modal-title {
  padding: 0;
  margin: 0;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 0px solid transparent;
    outline: 0;
    padding: 2rem 1rem;
}

.modal-content .btn {
  padding: .6rem 0;
}

.modal-content ul {
  margin-bottom: 2rem;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .5rem 0;
    border-top: 0px solid transparent;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 1px solid #eee;
}

small {
  font-size: .6rem;
}

@media (min-width: 576px) {
    .modal-body {
        max-width: 500px;
    }
}
@media (min-width: 768px) {
    .modal-body {
        max-width: 700px;
    }
}

@media (min-width: 992px) {
    .modal-body {
        max-width: 900px;
    }
}

@media (min-width: 1200px) {
    .modal-body {
        max-width: 1100px;
    }
}

.close-button {
    appearance: auto;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    background-color: transparent;
    box-sizing: border-box;
    margin: 0em;
    font: 400 18px Arial;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: transparent;
    border-image: initial;
    color: rgb(99, 99, 99);
}


</style>
