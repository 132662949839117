<template>
  <div class="home">
    <HeaderHome />
    <Featured/>
    <Brojke />
    <FeaturedInstructors />
    <CallToAction />
    <Instructor-ad/>
  </div>
</template>

<script>
import HeaderHome from '@/components/home/HeaderHome.vue'
import Featured from '@/components/home/Featured.vue'
import InstructorAd from '@/components/home/InstructorAd.vue'
import Brojke from '@/components/home/Brojke.vue'
import FeaturedInstructors from '@/components/home/FeaturedInstructors.vue'
import CallToAction from '@/components/home/CallToAction.vue'

export default {
  name: 'Home',
  components: {
    HeaderHome,
    Featured,
    InstructorAd,
    Brojke,
    FeaturedInstructors,
    CallToAction
  }
}
</script>
