<template>
  <div class="admin-main-section">
    <div class="container">
      <div class="row">
        <!-- main content -->
        <div class="col-12 admin-main-section-header">
          <h3>Termini</h3>
        </div>
        <div class="col-12">
          <Calendar
            ref="calendar"
            :attributes="attributes"
            locale="hr"
            is-expanded
            :min-date="today"
            :disabled-dates="disabledDates"
            @dayclick="dayClicked"
          >
          </Calendar>
        </div>
      </div>
    </div>

    <div v-if="showModal">
        <div class="modal">
            <div class="modal-body row justify-content-center align-items-center">
                <div v-if="modalLvl === 1" class="col-12">
                    <div class="row">
                        <div class="modal-header header-2 col-12 align-items-center">
                            <span class="far fa-calendar-alt"></span>
                            <h4 class="modal-title">{{ modalTitle }}</h4>
                            <button class="close-button" @click="toggleModal">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                        <div class="modal-content col-12">
                            <div class="row">
                                <div class="col-12 mb-4 text-right">
                                    <button class="btn termin-btn"><span class="fas fa-plus mr-2"></span>Dodaj termin</button>
                                </div>
                                <div v-for="(t, index) in dostupniTermini" :key="index" class="col-12 mTermin">
                                    <div @click="showTerminDetalis(t.termin)">
                                        <h5>{{t.termin}} h</h5>
                                        <small>{{t.polaznici}} polaznika</small>
                                    </div>
                                    <div class="mTermin-right">
                                        <button class="btn btn-circle mr-1">
                                            <span class="fas fa-ellipsis-h"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="modalLvl === 2" class="col-12">
                    <div class="row">
                        <div class="modal-header header-2 col-12 align-items-center">
                            <span class="far fa-calendar-alt"></span>
                            <h4 class="modal-title">{{ modalTitle }}</h4>
                            <button class="close-button" @click="toggleModal">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                        <div class="col-12 modal-content">
                            <div class="row">
                                <div
                                v-for="(trainee, index) in trainees"
                                :key="index"
                                class="col-12 polaznik-div"
                                :class="trainee.paid ? 'borderGreen' : 'borderRed'"
                                >
                                    <div class="row no-gutters">
                                        <div class="col-12 polaznik-header">
                                        <div class="polaznik-header-left">
                                            <h5 class="polaznik-username">{{ trainee.vendor.name }}</h5>
                                            <div class="mt-1 pl-2">
                                            <h6>
                                                <a href="callto:0987654321"
                                                ><span class="fas fa-phone mr-3 plava"></span
                                                ></a>
                                                <a href="mailto:example@gmail.com"
                                                ><span class="far fa-envelope mr-3 plava"></span
                                                ></a>
                                                <a href="#"
                                                ><span class="far fa-calendar-alt plava"></span
                                                ></a>
                                            </h6>
                                            </div>
                                        </div>
                                        <div class="polaznik-header-right">
                                            <button class="btn btn-circle mr-1">
                                            <span class="fas fa-ellipsis-h"></span>
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer col-12 justify-content-center align-items-center">
                  <small>Powered by Figaro</small>
                </div>
            </div>
        </div>
        <div class="modal-bg">
        </div>
    </div>

  </div>
</template>

<script>
import { Calendar } from "v-calendar";

export default {
  name: "AdminVendorTermini",
  components: {
    Calendar,
  },
  data() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    return {
      showModal: false,
      modalLvl: 0,
      modalTitle: "",
      today: date,
      disabledDates: [
            new Date(year, month, 9).getTime(),
            new Date(year, month, 16).getTime(),
            new Date(year, month, 23).getTime(),
      ],
      attributes: [
        {
          key: 'confirmed',
          highlight: {
            color: 'green',
            fillMode: 'light',
            contentClass: 'italic',
          },
          dates: [
            new Date(year, month, 3).getTime(),
            new Date(year, month, 5).getTime(),
            new Date(year, month, 7).getTime(),
          ]
        }
      ],
      dostupniTermini : [
          {termin: "09.00", polaznici: 4}, 
          {termin: "10.00", polaznici: 12},
          {termin: "11.00", polaznici: 21},
          {termin: "12.00", polaznici: 14},
          {termin: "13.00", polaznici: 7},
          {termin: "14.00", polaznici: 0},
          {termin: "15.00", polaznici: 6},
          {termin: "16.00", polaznici: 25},
    ],
      selectedDate: "",
      trainees: [
        {
          vendor: {
            name: "Davor Mikulić",
          },
          rating: 4,
          created_at: "2021-12-30",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
          edit: false,
          response: {
            created_at: "2021-12-31",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          },
          paid: true,
        },
        {
          vendor: {
            name: "Jasna Kolimbatović",
          },
          rating: 3,
          created_at: "2021-12-30",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
          edit: false,
          response: {
            created_at: "",
            text: "",
          },
          paid: true,
        },
        {
          vendor: {
            name: "Mate Matić",
          },
          rating: 3,
          created_at: "2021-12-30",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
          edit: false,
          response: {
            created_at: "",
            text: "",
          },
          paid: false,
        },
      ],
    };
  },
  methods: {
    toggleModal () {
      this.showModal = !this.showModal;
    },
    dayClicked(day){
      const date = day.date.getTime();
      const disabledDates = this.disabledDates;
      const availDate = disabledDates.indexOf(date);

      this.selectedDate = date;

      // ako odabrani datum nije u arrayu disabled datuma
      if (availDate === -1) {

        this.modalDate= new Date (date).toLocaleDateString('hr-HR')
        this.modalLvl= 1;
        this.modalTitle = "Termini";

        this.toggleModal ();

      }
    },
    showTerminDetalis(termin){
        this.modalLvl = 2;
        this.modalTitle = termin + " h";

    },
    changeModalLvl(){
      this.modalLvl = 2
      this.modalTitle = "Izaberi termin";
    },
    onTimeClick() {
      console.log()
    }
  },
};
</script>

<style scoped>
.admin-main-section {
  background: #fff;
  box-shadow: 2.828px 2.828px 15px 0 rgb(0 0 0 / 20%);
  transition: all 0.3s ease;
  padding: 1rem 0;
}

.carousel__slide--active > .carousel__item {
  font-weight: bold;
  padding: 0.5rem 0.9rem;
  background: tomato;
  color: #fff;
  border-radius: 50%;
}

/* modal stilovi */

body {
    overflow: hidden;
}

.modal-bg {
    opacity: .25;
    background-color: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
}

.modal {
    position: fixed;
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 10000;
}

.modal-body {
    position: relative;
    padding: 0;
    margin: 15px;
    margin-top: 10vh;
    background-color: #fff;
    border-radius: 5px;
    max-height: 80vh;
    overflow-y: auto;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 0px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: #c6f6d5;
    padding: 1rem;
}

.header-2 {
    background: #c5e1ff;
}

.modal-header span {
    font-size: 1.7rem;
    color: #fff;
}

.modal-title {
  padding: 0;
  margin: 0;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 0px solid transparent;
    outline: 0;
    padding: 2rem 1rem;
}

.modal-content .btn {
  padding: .6rem 0;
}

.mTermin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  background: #fff;
  padding: .5rem;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 10px 0 #00000033;
  margin-bottom: 0.2rem;
}

.mTermin h5 {
    margin: 0;
    padding: 0;
}

.mTermin-right {
  margin-left: auto;
}

.btn-circle {
  width: 2rem !important;
  height: 2rem !important;
  padding: 6px 0px !important;
  border-radius: 1rem !important;
  font-size: 0.7rem !important;
  text-align: center !important;
  background: #eee;
}

.modal-content ul {
  margin-bottom: 2rem;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .5rem 0;
    border-top: 0px solid transparent;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 1px solid #eee;
}

small {
  font-size: .6rem;
}

@media (min-width: 576px) {
    .modal-body {
        max-width: 500px;
    }
}
@media (min-width: 768px) {
    .modal-body {
        max-width: 700px;
    }
}

@media (min-width: 992px) {
    .modal-body {
        max-width: 900px;
    }
}

@media (min-width: 1200px) {
    .modal-body {
        max-width: 1100px;
    }
}

.close-button {
    appearance: auto;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    background-color: transparent;
    box-sizing: border-box;
    margin: 0em;
    font: 400 18px Arial;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: transparent;
    border-image: initial;
    color: rgb(99, 99, 99);
}

.termin-btn {
  padding: .6rem .8rem !important;
  background-color: #1D9AE6;
  color: white;
}

.termin-btn:hover {
  background-color: #1D9AE6;
  color: white;
}

/* kalendar */

.termin-ul span {
  color: #1D9AE6;
  font-size: .8rem;
  min-width: 1rem;
  text-align: center;
  margin-right: 1rem;
}

.termini-row {
  padding: .5rem;
  background-color: #fff;
  border: 1px solid;
  border-color: #cbd5e0;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}

.termini-box {
  color: #000;
  background: transparent;
  border: 1px solid transparent;
  margin: 5px;
  padding: 5px 0;
  text-align: center;
  font-size: .7rem;
  font-weight: 500;
}

.termini-box:hover {
  cursor: pointer;
  background: #edf2f7;
}

.clicked {
  color: #fff;
  background: #1D9AE6;
  border: 1px solid #1D9AE6;
}

/* polaznici */

.polaznik-div {
  background: #fff;
  transition: all 0.3s ease;
  box-shadow: 0px 2.828px 5px 0 #00000033;
  margin-bottom: 0.2rem;
}

.action-bar {
  align-items: center;
  justify-content: space-between;
}

.action-bar h6{
    margin: 0;
    text-align: center;
}

.borderGreen {
  border-left: 0.5rem solid rgb(172, 255, 179);
}

.borderRed {
  border-left: 0.5rem solid rgb(255, 165, 165);
}

.polaznik-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.polaznik-header-right {
  margin-left: auto;
  margin-bottom: 0.5rem;
}

h5 {
  font-weight: 500;
}

h6 {
  font-weight: 300;
}

.polaznik-btns {
  text-align: right;
}

</style>
