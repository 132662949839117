<template>
  <section id="instad" class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-xl-7 col-lg-6 col-md-12">
                <div class="section-header">
                <p class="section-subtitle-side">Postani partner treniraj.hr</p>
                <h2 class="section-title-side">Usredotoči se na ono što voliš raditi</h2>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p>Treniraj.hr aplikacija je snažan ali i jednostavan alat za vođenje evidencije termina treninga. Zadržite postojeće i privucite nove korisnike uz treniraj.hr.</p>
                        <ul class="instad-ul my-4">
                            <li><i class="fas fa-check"></i>0/24 primajte rezervacije termina</li>
                            <li><i class="fas fa-check"></i>Vodite evidenciju dolazaka na treninge</li>
                            <li><i class="fas fa-check"></i>Pojednostavnite komunikaciju s klijentima</li>
                        </ul>
                    </div>
                    <div class="col-12">
                        <a class="btn btn-lg  btn-primary m-1" href="#">Postani trener</a>
                        <a class="crvena ml-3 d-none d-sm-inline" href="#"><i class="fas fa-play mr-2"></i>Pogledaj Video</a>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-lg-6 d-none d-lg-block">
                <img src="@/assets/trener-ad.jpg" alt="" class="img-responsive" />
            </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "InstructorAd",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
