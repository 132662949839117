<template>
  <footer>
    <div class="container py-5">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3 footer-col">
            <img
              src="@/assets/logo-bili.png"
              alt=""
              class="img-responsive mb-3 pr-3"
            />
            <p class="text-justify">
              Br. 1 aplikacija za organizaciju poslovanja i pronalazak trener i klubova
            </p>
            <div class="social-icons">
                <a href="#" title="Facebook">
                    <div class="fb">
                        <i class="fab fa-facebook-f"></i>
                    </div>
                </a>
                <a href="#" title="Instagram">
                    <div class="ig">
                        <i class="fab fa-instagram"></i>
                    </div>
                </a>
                <a href="#" title="Google+">
                    <div class="gg">
                        <i class="fab fa-google"></i>
                    </div>
                </a>
            </div>
          </div>
          <div class="col-md-2 footer-col">
            <h4>Treninzi</h4>
            <ul>
              <li><a href="#">Fitness</a></li>
              <li><a href="#">Cross Fit</a></li>
              <li><a href="#p">Yoga</a></li>
              <li><a href="#">Pilates</a></li>
            </ul>
          </div>
          <div class="col-md-2 footer-col">
            <h4>Zajednica</h4>
            <ul>
              <li><a href="#">Zdrava prehrana</a></li>
              <li><a href="#">Sportska oprema</a></li>
              <li><a href="#p">Blog & Motivacija</a></li>
              <li><a href="#">Za trenere</a></li>
            </ul>
          </div>
          <div class="col-md-2 footer-col">
            <h4>Korisni Linkovi</h4>
            <ul>
              <li><a href="#p">O nama</a></li>
              <li><a href="#">Kontaktirajte nas</a></li>
              <li><a href="#">Pravila privatnosti</a></li>
              <li><a href="#">Uvjeti korištenja</a></li>
            </ul>
          </div>
          <div class="col-md-3 footer-col">
            <h4>Trebate pomoć?</h4>
            <p>Ako imate pitanja ili trebate pomoć u vezi korištenja stranice slobodno nas kontaktirajte.</p>
            <a href="callto:+385994735263">
              <h5 class="footer-kontakt">+385 99 473 5263</h5>
            </a>
            <a href="mailto:info@treniraj.hr">
              <h5 class="footer-kontakt">info@treniraj.hr</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid developer">
      <p class="text-center">
        &copy; treniraj.hr powered by:
        <a href="https://tino.figaro.hr/" id="tino">Figaro</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
footer {
  background: #1D9AE6;
  color: rgba(255, 255, 255, .4);
  font-size: .8rem;
}

p {
  color: rgba(255, 255, 255, .4);
}

h4 {
  color: #fff;
  font-size: .9rem;
  padding-bottom: 1.2rem;
}

.footer-kontakt {
  color: rgba(255, 255, 255, .8) !important;
}

.footer-col {
  margin-top: 1rem;
}

.footer-kontakt:hover {
  color: #fff !important;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  padding-bottom: .7rem;
}

li a {
  color: rgba(255, 255, 255, .4);
}

li a:hover {
  color: rgb(255, 255, 255);;
}

.social-icons {
  display: flex;
  align-items: left;
  justify-content: left;
}

.social-icons a {
  color: rgba(255, 255, 255, .4);
}

.fb, .ig, .gg {
    background-color: rgba(255, 255, 255, .1);
    margin: .15rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all .3s ease;
}

.fb:hover {
    background-color: #3B5998;
    color: rgb(255, 255, 255);
}
.ig:hover {
    background-color: #E1306C;
    color: rgb(255, 255, 255);
}
.gg:hover {
    background-color: #dd4b39;
    color: rgb(255, 255, 255);
}

.developer {
  background: #1a1a1a;
  padding: 30px 0 10px 0;
}

#tino {
  color: #fff;
}

#tino:hover {
  color: #fff;
}
</style>
