<template>
  <div class="admin-main-section">
    <div class="container">
      <div class="row">
        <!-- main content -->
        <div class="col-12 admin-main-section-header">
          <h3>Postavke profila</h3>
        </div>
        <div class="col-12">
          <table class="table table-hover table-borderless bizInfoTable">
            <tr>
              <th>Korisničko ime:<span class="required">*</span></th>
              <td class="bizInfoInput">
                <input
                  type="text"
                  v-model="user.name"
                  placeholder="Unesite korisničko ime"
                />
              </td>
            </tr>
            <tr>
              <th>E-adresa:<span class="required">*</span></th>
              <td class="bizInfoInput">
                <input type="text" :value="user.email" disabled="true" />
              </td>
            </tr>
            <tr>
              <th>Kontakt:<span class="required">*</span></th>
              <td class="bizInfoInput">
                <input
                  type="text"
                  v-model="user.contact"
                  placeholder="Unesite kontakt broj"
                />
              </td>
            </tr>
            <br><br>
            <b>Promijeni lozinku</b>
            <tr>
              <th>Trenutna lozinka:<span class="required">*</span></th>
              <td class="bizInfoInput">
                <input
                  type="password"
                  v-model="current_password"
                  placeholder="Unesite trenutnu lozinku"
                />
              </td>
            </tr>
            <tr>
              <th>Nova lozinka:<span class="required">*</span></th>
              <td class="bizInfoInput">
                <input
                  type="password"
                  v-model="password"
                  placeholder="Unesite novu lozinku"
                />
              </td>
            </tr>
            <tr>
              <th>Potvrdi novu lozinku:<span class="required">*</span></th>
              <td class="bizInfoInput">
                <input
                  type="password"
                  v-model="password_confirmation"
                  placeholder="Potvrdite novu lozinku"
                />
              </td>
            </tr>
          </table>

          <button @click="save()" class="btn btn-lg btn-primary">
            <span><span class="fas fa-check mr-2"></span> Ažuriraj profil</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Postavke",
  components: {},
  data() {
    return {
      user: {
        name: "",
        email: "",
        contact: ""
      },
      password: '',
			current_password: '',
			password_confirmation: ''
    };
  },
};
</script>

<style scoped>

/* tablica za admin panel */

.bizInfoTable tr:hover {
	cursor: pointer;
}

.bizInfoTable td,
.bizInfoTable th {
	padding: .75rem;
	vertical-align: top;
	border-top: 1px transparent;
}

.bizInfoTable th {
	font-weight: bold;
	white-space: nowrap;
	width: 1%;
}

.bizInfoInput input,
.bizInfoInput select {
	width: 100%;
	background: transparent;
	border: none;
	border-bottom: solid 1px #3d3d3d;
}

.bizInfoInput input:focus {
	border: 1px solid red;
}

.bizInfoInput span {
	display: inline-block;
	overflow-x: hidden;
	white-space: nowrap;
}

.bizInfoInput textarea {
	background: transparent;
	border: solid 1px #3d3d3d;
	margin-top: 3px;
	font-size: .9em;
}

.bizInfoInput textarea::placeholder {
	color: #d4d4d4;
	font-size: 1em;
}

.bizInfoCheck {
	display: flex;
	flex-flow: row;
	justify-content: space-around;
}

.lbl {
	width: 100%;
	display: flex;
	font-weight: 300;
}

.lbl input {
	width: unset;
	margin-top: 5px;
	margin-right: 6px;
	margin-left: 10px;
}

@media (max-width: 576px) {
	.bizInfoCheck {
		display: flex;
		flex-flow: column;
		justify-content: space-around;
		margin-top: 3px;
	}

	.lbl {
		width: 100%;
		display: flex;
		font-weight: 300;
		flex-flow: row;
	}

	.lbl input {
		width: unset;
		margin-top: 5px;
		margin-right: 6px;
		margin-left: 0px;
	}
}

.bizInfoLink {
	display: block;
	text-align: right;
}

@media (max-width: 575.98px) {
	.bizInfoTable tr {
		display: flex;
		flex-wrap: wrap;
		padding: .5rem;
	}

	.bizInfoTable td,
	.bizInfoTable th {
		margin: 0px;
		padding: 0px;
		flex: 0 1 100%;
	}

	.bizInfoLink {
		display: none;
	}
}

</style>
