<template>
  <div>
    <Navbar />
    <Router-view />
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: '/',
  components: {
    Navbar,
    Footer
  }
}

</script>

<style lang="scss">
// ubaci globalne stilove
@import './assets/styles/main.scss';
</style>
