<template>
  <div class="searchResult">
    <div class="container">
      <div class="row">
        <div class="col-12 d-lg-none text-right">                 
          <a @click="filterToggle" class="btn btn-primary"><span class="fas fa-filter mr-2"></span> Filtriraj</a>
        </div>
        <div v-if="showFilter" class="col-lg-3 mt-3">
          <SearchSidebar />
        </div>
        <div class="col-lg-9 mt-3 mb-5">
          <SearchResults />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchSidebar from "@/components/searchList/SearchSidebar.vue";
import SearchResults from "@/components/searchList/SearchResults.vue";

export default {
  name: "SearchList",
  components: {
    SearchSidebar,
    SearchResults
  },
  data() {
    return {
      showFilter: false
    }
  },
  methods: {
    filterToggle (){
          this.showFilter = !this.showFilter
    },
    checkWidth(){
     let intViewportWidth = window.innerWidth;
      if (intViewportWidth > 992) {
        this.showFilter = true
      } else {
        this.showFilter = false
      }
    },
    onResize(event) {
      let w = event.currentTarget.innerWidth
      if (w > 992) {
        this.showFilter = true
      } else {
        this.showFilter = false
      }
    }
  },
  mounted() {
    this.checkWidth()
    window.addEventListener('resize', this.onResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize)
  }
};
</script>

<style scoped>
.searchResult {
  padding: 3.5rem 0;
}

.filter-btn {
  padding: .6rem 0;
  background-color: #1D9AE6;
  color: white;
}

.filter-btn:hover {
  cursor: pointer;
}

</style>
