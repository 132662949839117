<template>
  <section id="featured" class="container">
    <div class="row">
      <div class="col-xl-5 col-lg-6 d-none d-lg-block">
        <img src="@/assets/featured.jpg" alt="" class="img-responsive">
      </div>
      <div class="col-xl-7 col-lg-6 col-md-12">
        <div class="section-header">
					<p class="section-subtitle-side">Zašto treniraj.hr?</p>
					<h2 class="section-title-side">Svi treneri na jednom mjestu</h2>
				</div>
        <div class="row">
          <div class="col-12 box d-flex align-items-center">
						<div class="icon"><i class="fas fa-award"></i></div>
            <div class="box-text">
              <h4 class="title">Pouzdani treneri</h4>
              <p class="description">Samo provjereni i licencirani treneri koji znaju kako najbolje iskoristiti tvoje potencijale i ostvariti maksimalni učinak.</p>
            </div>
					</div>
          <div class="col-12 box d-flex align-items-center">
						<div class="icon"><i class="fa fa-thumbs-up"></i></div>
            <div class="box-text">
              <h4 class="title">Jednostavnost korištenja</h4>
              <p class="description">Treniraj.hr ti pruža sve potrebne funkcionalnosti kako bi se u potpunosti mogao posvetiti treningu. Naruči se i treniraj!</p>
            </div>
					</div>
          <div class="col-12 box d-flex align-items-center">
						<div class="icon"><i class="fas fa-rocket"></i></div>
            <div class="box-text">
              <h4 class="title">Prvi trening je besplatan</h4>
              <p class="description">Kako bi se zaista uvjerio/la u kvalitetu određenog trenera treniraj.hr ti osigurava prvi trening potpuno besplatno!</p>
            </div>
					</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Featured',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
