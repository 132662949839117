<template>
  <div class="admin-main-section">
    <div class="container">
      <div class="row">
        <!-- main content -->
        <div class="col-12 admin-main-section-header">
          <h3>Kalkulatori</h3>
        </div>
        <div class="col-12">
          <h5 @click="modalBMI">Body Mass Index</h5>
          <h5 @click="modalKalorije">Potrošnja kalorija</h5>
        </div>
      </div>
    </div>

    <!-- modali -->
    <div v-if="showModal">
      <div class="modal">
        <div class="modal-body row justify-content-center align-items-center">
          <div v-if="modalLvl === 1" class="col-12">
            <div class="row">
              <div class="modal-header header-1 col-12 align-items-center">
                <span class="fas fa-tachometer-alt"></span>
                <h4 class="modal-title">{{ modalTitle }}</h4>
                <button class="close-button" @click="toggleModal">
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div class="modal-content col-12">
                <table class="table table-hover table-borderless bizInfoTable">
                  <tr>
                    <th>Visina (cm):<span class="required"></span></th>
                    <td class="bizInfoInput">
                      <input type="number" placeholder="Unesite Vašu visinu" />
                    </td>
                  </tr>
                  <tr>
                    <th>Težina (kg):<span class="required"></span></th>
                    <td class="bizInfoInput">
                      <input type="number" placeholder="Unesite Vašu težinu" />
                    </td>
                  </tr>
                </table>

                <button class="btn btn-md btn-primary">
                  <span><span class="fas fa-check mr-2"></span> Izračunaj</span>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer col-12 justify-content-center align-items-center">
            <small>Powered by Figaro</small>
          </div>
        </div>
      </div>
      <div class="modal-bg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Kalkulator",
  components: {},
    data() {
    return {
      showModal: false,
      modalLvl: 0,
      modalTitle: ""
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    modalBMI() {
      this.showModal = true;
      this.modalLvl = 1;
      this.modalTitle = "Body Mass Index";
    }
  },
};
</script>

<style scoped>
.btn-md {
  padding: 0.8rem 1rem;
}

/* tablica za admin panel */

.bizInfoTable tr:hover {
  cursor: pointer;
}

.bizInfoTable td,
.bizInfoTable th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px transparent;
}

.bizInfoTable th {
  font-weight: bold;
  white-space: nowrap;
  width: 1%;
}

.bizInfoInput input,
.bizInfoInput select {
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: solid 1px #3d3d3d;
}

.bizInfoInput input:focus {
  border: 1px solid red;
}

.bizInfoInput span {
  display: inline-block;
  overflow-x: hidden;
  white-space: nowrap;
}

.bizInfoInput textarea {
  background: transparent;
  border: solid 1px #3d3d3d;
  margin-top: 3px;
  font-size: 0.9em;
}

.bizInfoInput textarea::placeholder {
  color: #d4d4d4;
  font-size: 1em;
}

.bizInfoCheck {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}

.lbl {
  width: 100%;
  display: flex;
  font-weight: 300;
}

.lbl input {
  width: unset;
  margin-top: 5px;
  margin-right: 6px;
  margin-left: 10px;
}

@media (max-width: 576px) {
  .bizInfoCheck {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    margin-top: 3px;
  }

  .lbl {
    width: 100%;
    display: flex;
    font-weight: 300;
    flex-flow: row;
  }

  .lbl input {
    width: unset;
    margin-top: 5px;
    margin-right: 6px;
    margin-left: 0px;
  }
}

.bizInfoLink {
  display: block;
  text-align: right;
}

@media (max-width: 575.98px) {
  .bizInfoTable tr {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
  }

  .bizInfoTable td,
  .bizInfoTable th {
    margin: 0px;
    padding: 0px;
    flex: 0 1 100%;
  }

  .bizInfoLink {
    display: none;
  }
}

/* modal stilovi */

body {
    overflow: hidden;
}

.modal-bg {
    opacity: .25;
    background-color: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
}

.modal {
    position: fixed;
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 10000;
}

.modal-body {
    position: relative;
    padding: 0;
    margin: 15px;
    margin-top: 10vh;
    background-color: #fff;
    border-radius: 5px;
    max-height: calc(100vh - 30px);
    overflow-y: auto;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 0px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 1rem;
}

.header-1 {
  background: #c5e1ff;
}

.header-2 {
    background: #c5e1ff;
}

.modal-header span {
    font-size: 1.7rem;
    color: #fff;
}

.modal-title {
  padding: 0;
  margin: 0;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 0px solid transparent;
    outline: 0;
    padding: 2rem 1rem;
}

.modal-content .btn {
  padding: .6rem 0;
}

.modal-content ul {
  margin-bottom: 2rem;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .5rem 0;
    border-top: 0px solid transparent;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 1px solid #eee;
}

small {
  font-size: .6rem;
}

@media (min-width: 576px) {
    .modal-body {
        max-width: 500px;
    }
}
@media (min-width: 768px) {
    .modal-body {
        max-width: 700px;
    }
}

@media (min-width: 992px) {
    .modal-body {
        max-width: 900px;
    }
}

@media (min-width: 1200px) {
    .modal-body {
        max-width: 1100px;
    }
}

.close-button {
    appearance: auto;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    background-color: transparent;
    box-sizing: border-box;
    margin: 0em;
    font: 400 18px Arial;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: transparent;
    border-image: initial;
    color: rgb(99, 99, 99);
}
</style>
