import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Instruktor from '../views/Instruktor.vue'
import SearchList from '../views/SearchList.vue'

import AdminUserIndex from '../views/adminUser/Index.vue'
import AdminUserTermini from '../views/adminUser/Termini.vue'
import AdminUserKalkulator from '../views/adminUser/Kalkulator.vue'
import AdminUserKomentari from '../views/adminUser/Komentari.vue'
import AdminUserPostavke from '../views/adminUser/Postavke.vue'

import AdminVendorIndex from '../views/adminVendor/Index.vue'
import AdminVendorTermini from '../views/adminVendor/AdminVendorTermini.vue'
import AdminVendorPolaznici from '../views/adminVendor/AdminVendorPolaznici.vue'
import AdminVendorKomentari from '../views/adminVendor/AdminVendorKomentari.vue'
import AdminVendorPostavke from '../views/adminVendor/AdminVendorPostavke.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/search-list',
    name: 'SearchList',
    component: SearchList
  },
  {
    path: '/instruktor/:id',
    name: 'Instruktor',
    component: Instruktor
  },
  {
    path: '/korisnik',
    name: 'AdminUserIndex',
    component: AdminUserIndex,
    children: [
      {
        path: 'termini',
        name: 'uTermini',
        component: AdminUserTermini
      },
      {
        path: 'kalkulator',
        name: 'uKalkulator',
        component: AdminUserKalkulator
      },
      {
        path: 'komentari',
        name: 'uKomentari',
        component: AdminUserKomentari
      },
      {
        path: 'postavke',
        name: 'uPostavke',
        component: AdminUserPostavke
      }
    ]
  },
  {
    path: '/trener',
    name: 'AdminVendor',
    component: AdminVendorIndex,
    children: [
      {
        path: 'termini',
        name: 'vTermini',
        component: AdminVendorTermini
      },
      {
        path: 'polaznici',
        name: 'vPolaznici',
        component: AdminVendorPolaznici
      },
      {
        path: 'komentari',
        name: 'vKomentari',
        component: AdminVendorKomentari
      },
      {
        path: 'postavke',
        name: 'vPostavke',
        component: AdminVendorPostavke
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router