import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    // za koristit state u komponenti koristimo ...mapState() u computedu

    endpoint:'https://api-treniraj.figaro.hr/api/',
		// endpoint: 'http://127.0.0.1:8000/api/',
    loading: false,
    instruktori: [],
    instruktor: null,
    komentari: [],
    cijene: [],
    predmeti: []
  },
  mutations: {
    // jedini nacin za minjat state
    // @click="$store.commit('imeMutacije')"
    // nemogu bit async nego se odma moraju izvrsit
    // nemos recimo cekat da ti dodje data iz apija ode
    // mora imat state parametar to mu vue salje automatski
    // za koristit mutacije u komponenti koristimo ...mapMutations() u metodama
    setLoading (state, loading) {
      state.loading = loading
    },
    getFeaturedInstruktori (state, instruktori) {
      state.instruktori = instruktori
    },
    getInstruktori (state, instruktori) {
      state.instruktori = instruktori
    },
    getInstruktor (state, instruktor) {
      state.instruktor = instruktor
    },
    getKomentari (state, komentari) {
      state.komentari = komentari
    },
    getCijene (state, cijene) {
      state.cijene = cijene
    }
  },
  actions: {
    // nemogu minjat state ali mogu access to data u state
    // ako zelimo minjat data u stateu iz akcija moramo commitat mutaciju
    // mogu bit asinkroni
    // @click="$store.dispatch('imeAkcije')"
    // npr mozemo trazit data iz apija i kad dodje data onda commitamo mutaciju koja minja data
    // da bi uzeja random broj od APIja i koristija gau u promjeni state triba zvat ovu akciju sa dispatch
    // za koristit actions u komponenti koristimo ...mapActions() u metodama
    getFeaturedInstruktori ({ commit, state }) {
      axios(state.endpoint + 'get-featured-instruktors').then(response => {
        commit('getFeaturedInstruktori', response.data)
      })
    },
    getInstruktori ({ commit, state }) {
      axios(state.endpoint + 'get-instruktors').then(response => {
        commit('getInstruktori', response.data)
      })
    },
    getInstruktor ({ commit, state }, id) {
      commit('setLoading', true)
      axios(state.endpoint + 'get-instruktor/' + id).then(response => {
        commit('getInstruktor', response.data)
      })
      commit('setLoading', false)
    },
    getKomentari ({commit, state}, id) {
      commit('setLoading', true)
      axios(state.endpoint + 'get-komentari/' + id).then(response => {
        commit('getKomentari', response.data)
      })
      commit('setLoading', false)
    },
    getCijene ({commit, state}, id) {
      commit('setLoading', true)
      axios(state.endpoint + 'get-cijene/' + id).then(response => {
        commit('getCijene', response.data)
      })
      commit('setLoading', false)
    },
    postInstruktorFormEmail ({state}, mail) {
      axios.post(state.endpoint + 'post-instruktor-form-email', mail);
    }

    // https://www.youtube.com/watch?v=nFh7-HfODYY
  },
  getters: {
    // getter sluzi za dobavit data iz statea
    // korisno je kad triba filtrat ili nesto ucinit s data prije nego ja dobacimo u component
    // {{ $store.getters.getPredmeti }}
    //
    predmeti: (state) => {
      return state.cijene.filter((obj, pos, arr) => {
         return arr.map(mapObj => mapObj["predmet_id"]).indexOf(obj["predmet_id"]) === pos;
      });
    }

  },
  modules: {
    // ovo sluzi za razbit store po stranicama npr.
  }
})
