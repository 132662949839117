<template>
    <nav id="mainNav" class="navbar navbar-expand-md navbar-light fixed-top bg-light">
        <div class="container">
            <router-link :to="'/'">
                <img src="@/assets/logo.png" alt="" class="logo">
            </router-link>
            <button class="navbar-toggler" :class="navbarShow ? '' : 'collapsed'" type="button" data-toggle="collapse" data-target="#navbarCollapse">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div @click="toggleNavbar" :class="navbarShow ? 'show' : ''" class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav ml-auto">
                    <li @click="toggleNavbar" class="nav-item">
                        <router-link to="/" class="nav-link">Početna</router-link>
                    </li>
                    <li @click="toggleNavbar" class="nav-item">
                        <router-link to="/search-list" class="nav-link">Treneri</router-link>
                    </li>
                    <li @click="toggleNavbar" class="nav-item">
                        <router-link to="/trener/termini" class="nav-link">Trener</router-link>
                    </li>
                    <li @click="toggleNavbar" class="nav-item">
                        <router-link to="/korisnik/termini" class="nav-link">Korisnik</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>

export default {
    name: 'Navbar',
    data() {
        return {
            navbarShow: false
        }
    },
    methods: {
        toggleNavbar() {
            this.navbarShow = !this.navbarShow
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.logo {
	display: block;
	max-height: 50px;
	width: auto;
}
</style>