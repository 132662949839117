<template>
<div class="">
    <!-- koliko je pronađeno rezultata -->
    <!-- rezultati -->
    <div v-for="(i, index) in instruktori" :key="index" class="mb-2 search-result">
      <!-- rezultat -->
    <router-link :to="'/instruktor/'+i.id">
    <div class="row">
      <div class="col-12">
        <div class="row no-gutters">
          <!-- slika profila -->
          <div class="col-4 col-sm-3">
            <div
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/instruktori/' + i.slika + '') + ')',
              }"
              class="img-wrapper"
            ></div>
          </div>
          <!-- podaci profila -->
          <div class="col-8 col-sm-6">
            <div class="container my-3">
              <div class="row">
                <div class="col-12">
                  <h5>{{ i.ime }}</h5>
                </div>
                <div class="col-12 d-sm-none">
                  <div class="stars">
                    <p>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                      <span class="ml-1">{{ i.broj_ocjena }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-12">
                  <p>
                    <span class="mr-3"><i class="fa fa-user mr-2"></i>{{ i.zvanje}}</span>
                    <br class="d-sm-none">
                    <span><i class="fa fa-tag mr-2"></i>{{i.cijena}} kn/mj</span>
                  </p>
                </div>
                <div class="col-12">
                  <hr />
                  <p class="opis d-none d-sm-block">{{ i.kratki_opis.length > 150 ? i.kratki_opis.substr(0,150) + '...' : i.kratki_opis }}</p>
                </div>
                <div class="col-12 mt-2">
                  <span v-for="(predmet, index) in i.predmeti" :key="index" class="badge badge-predmet">{{predmet.ime}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- botun profila -->
          <div class="col-3 d-none d-sm-block">
            <div class="row h-100 align-items-center">
              <div class="col-12 text-center">
                <div class="stars">
                  <p>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="ml-1">
                      <b>{{ i.ocjena }}</b>
                      <br />
                      <small>({{ i.broj_ocjena }} ocjena)</small>
                    </span>
                  </p>
                </div>
                <router-link :to="'/instruktor/'+i.id" class="btn btn-lg btn-primary">Vidi više</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </router-link>
  </div>
</div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SearchResults",
  mounted() {
    this.$store.dispatch('getInstruktori');
  },
  computed: {
    ...mapState({
        instruktori: state => state.instruktori,
    }),
  }
}
</script>

<style scoped lang="scss">
p {
  margin: 0 !important;
  padding: 0 !important;
}

hr {
  margin-top: 0.4rem;
  margin-bottom: 0.2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.badge {
  margin: .1rem;
  border-radius: 0;
  padding: .5rem;
}

.badge-predmet {
  border: 1px solid #1D9AE6;
  color: #1D9AE6;
  font-size: .55rem;
}

.badge-predmet:hover {
  border: 1px solid #1D9AE6;
  color: #fff;
  background: #1D9AE6;
  font-size: .55rem;
  cursor: pointer;
}

.search-result {
  background: #fff;
  box-shadow: 2.828px 2.828px 15px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;
}

.search-result:hover {
  -webkit-box-shadow: 0px 14px 25px -18px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 14px 25px -18px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 14px 25px -18px rgba(0, 0, 0, 0.5);
}

.opis {
  font-size: 0.65rem;
  text-align: justify;
}

.stars {
  margin-bottom: 1rem;
}

.fa {
  color: #1D9AE6;
}

.fa-star {
  color: lightgray;
}

.checked {
  color: rgb(243, 178, 0) !important;
}
</style>
