<template>
  <div id="sidebar" class="container py-4 mb-2">
    <div class="row">
      <SidebarCategory class="mb-3 col-sm-6 col-lg-12" />
      <SidebarFilter class="mb-3 col-sm-6 col-lg-12" />
      <SidebarStars class="mb-3 col-sm-6 col-lg-12" />
      <SidebarSlider class="mb-3 col-sm-6 col-lg-12" />
    </div>
  </div>
</template>

<script>

import SidebarCategory from "@/components/searchList/SidebarCategory.vue";
import SidebarStars from "@/components/searchList/SidebarStars.vue";
import SidebarSlider from "@/components/searchList/SidebarSlider.vue";
import SidebarFilter from "@/components/searchList/SidebarFilter.vue";

export default {
  name: 'SearchSidebar',
  components: {
    SidebarCategory,
    SidebarStars,
    SidebarSlider,
    SidebarFilter
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

#sidebar {
    min-height: 150px;
    background: #fff;
    box-shadow: 2.828px 2.828px 15px 0 rgba(0, 0, 0, 0.2);
    transition: all .5s ease;
}

</style>
