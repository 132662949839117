<template>
  <div class="FeaturedInstructors">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-header">
            <p class="section-subtitle-side">Najpopularnije</p>
            <h2 class="section-title-side">
              Pretražite naše najtraženije trenere
            </h2>
          </div>
        </div>
        <div class="col-12">
            <carousel id="featuredTrainersCarousel" :items-to-show="showItems" autoplay=2000>
              <slide v-for="(i, index) in instruktori" :key="index" :class="showItems >= 2 ? 'slide' : ''">
                <div class="row no-gutters w-100">
                  <div class="col-12">
                    <div
                      :style="{
                        backgroundImage:
                          'url(' + require('@/assets/instruktori/' + i.slika + '') + ')',
                      }"
                      class="img-wrapper"
                    ></div>
                  </div>
                  <div class="col-12 p-3">
                    <h5>{{ i.ime }}</h5>
                    <p class="kratki-opis">{{ i.kratki_opis.length > 80 ? i.kratki_opis.substr(0,80) + '...' : i.kratki_opis }}</p>
                    <h5><b>{{ i.cijena }} kn/mj</b></h5>
                    <div class="stars mb-4">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                      <span class="ml-1">
                        {{ i.ocjena }} ({{ i.broj_ocjena }})
                      </span>
                    </div>
                    <router-link :to="'/instruktor/'+i.id" class="btn btn-lg btn-primary btn-block">Vidi više</router-link>
                  </div>
                </div>
              </slide>

              <template #addons>
                <pagination />
              </template>
            </carousel>
        </div>
          </div>
        </div>
      </div>
</template>

<script>
import { mapState } from 'vuex'
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";

export default {
  name: "FeaturedInstructors",
  components: {
    Carousel,
    Slide,
    Pagination
  },
  data() {
    return {
      w: window.innerWidth,
      showItems: 0
    }
  },
  methods: {
    setShowItems(){
      const width = this.w;
      // XS
      if (width <= 575) {
        this.showItems = 1
      } 
      // MD
      else if (width <= 991) {
        this.showItems = 2
      } 
      // XL
      else {
        this.showItems = 3
      }
    }
  },
  mounted() {
    this.$store.dispatch('getFeaturedInstruktori')
    this.setShowItems()
  },
  computed: {
    ...mapState({
      instruktori: state => state.instruktori
  })
  }
};
</script>

<style scoped lang="scss">
/*
ovi carousel plugin nije jos do kraja razvijen pa sam onda 
pola stilova ovdje pola u node modulima mijenja,
kad stigne 1.0 verzija plugina onda cemo mijenjati ovo
*/

.slide {
  margin: 7.5px;
  min-height: 150px;
  background: #fff;
  box-shadow: 2.828px 2.828px 15px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;
}

.slide:hover {
  transform: scale(1.03, 1.03);
  -webkit-box-shadow: 0px 14px 25px -18px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 14px 25px -18px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 14px 25px -18px rgba(0, 0, 0, 0.5);
}

.img-wrapper {
  overflow: hidden;
  height: 10em;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.kratki-opis {
  height: 65px;
}

.fa-star {
  color: lightgray;
}

.checked {
  color: rgb(243, 178, 0) !important;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

ol {
    padding-inline-start: 0px !important;
}

</style>
