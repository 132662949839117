<template>
  <div class="adminUser">
    <div class="container">
      <div class="row">
        <!-- main content -->
        <div class="col-lg-8 col-md-12 order-md-2 my-3">
          <div class="tab-content">
            <Router-view />
          </div>
        </div>
        <!-- sidebar -->
        <div class="col-lg-4 col-md-12 order-1 my-3">
            <div id="sidebar">
                <div class="nav navbar-nav" role="tablist">					
                    <router-link to="/korisnik/termini" id="" class="nav-item nav-link active" data-toggle="pill" role="tab"><span class="far fa-calendar-alt fa-fw"></span>Termini</router-link>
                    <router-link to="/korisnik/kalkulator" id="" class="nav-item nav-link" data-toggle="pill" role="tab"><span class="fas fa-calculator"></span>Kalkulatori</router-link>
                    <router-link to="/korisnik/komentari" id="" class="nav-item nav-link" data-toggle="pill" role="tab"><span class="far fa-comments fa-fw"></span>Komentari</router-link>
                    <router-link to="/korisnik/postavke" id="" class="nav-item nav-link" data-toggle="pill" role="tab"><span class="fa fa-cog fa-fw"></span>Postavke Profila</router-link>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default { 
  name: 'Index',
  components: {
  },
}
</script>

<style scoped>

#sidebar {
    background: #fff;
    box-shadow: 2.828px 2.828px 15px 0 rgba(0, 0, 0, 0.2);
    transition: all .5s ease;
}

.tab-pane {
    background: #fff;
    box-shadow: 2.828px 2.828px 15px 0 rgba(0, 0, 0, 0.2);
    transition: all .3s ease;
}

.navbar-nav .nav-link {
	font-size: 1rem;
	color: #595959;
	border: 1px solid transparent;
	background: transparent;
	font-weight: 400;
	border-radius: 0px;
    padding-right: 1rem;
    padding-left: 1rem;
    margin: 0;
}

.nav-link:hover {
	background-color: #1D9AE6;
	color: #fff;
}

.navbar-nav .active {
    background-color: #1D9AE6;
	color: #fff;
}

.nav-link span {
    margin-right: 1rem;
}

</style>