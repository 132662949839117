<template>
<section id="callToAction" class="jumbotron paral paralsec">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="section-title mb-5">Registriraj se i iskoristi popust za <span>nove korisnike</span></h2>
      </div>
      <div class="col-12">
        <p class="text">U tijeku je <span>sniženje od 15%</span> za prvi mjesec treniranja. Broj korisnika koji mogu ostvariti popust je ograničen, zato požuri se registrirati na treniraj.hr i zgrabi svoj popust za nove korisnike odmah!</p>
      </div>
      <div class="col-12 text-center mt-3">
        <button class="btn btn-lg btn-primary">Registriraj se</button>
      </div>
    </div>
  </div>
</section>
</template>

<script>

export default {
  name: 'CallToAction',
}
</script>
<style scoped lang="scss">

section {
  padding: 100px 0;
  background: #000e27;
}

.section-title {
  color: #fff;
}

span {
  color: #1D9AE6;
}

.text {
  color: #fff;
  text-align: center;
}

</style>