<template>
  <div>
    <h5>Cijena</h5>
    <ul>
      <li><a class="category" href="#">Ispod 150 kn/mj</a></li>
      <li><a class="category" href="#">150 - 200 kn/mj</a></li>
      <li><a class="category" href="#">200 - 300 kn/mj</a></li>
      <li><a class="category" href="#">Preko 300 kn/mj</a></li>
    </ul>
    <Slider v-model="example2.value" v-bind="example2"></Slider>
  </div>
</template>

<script>
import Slider from "@vueform/slider";

export default {
  name: "SidebarSlider",
  components: { Slider },
  data: () => ({
    example2: {
      value: [0, 30],
    },
  }),
};
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style scoped lang="scss">
h5 {
    font-size: .9rem;
    margin-bottom: 5px;
}

li {
    line-height:1.5;
}

.category {
    font-size: .8rem;
    color: #212529;
}

</style>

<style lang="scss">
// slider css

.slider-horizontal {
    margin: 50px 0 25px 0;
    height: 6px;
}

.slider-connect {
  background: #1D9AE6;
}

.slider-tooltip {
    position: absolute;
    display: block;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    padding: 2px 5px;
    min-width: 20px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #1D9AE6;
    background: #1D9AE6;
}

</style>
