import { render } from "./SidebarSlider.vue?vue&type=template&id=243a9d68&scoped=true"
import script from "./SidebarSlider.vue?vue&type=script&lang=js"
export * from "./SidebarSlider.vue?vue&type=script&lang=js"

import "@vueform/slider/themes/default.css?vue&type=style&index=0&lang=css"
import "./SidebarSlider.vue?vue&type=style&index=1&id=243a9d68&scoped=true&lang=scss"
import "./SidebarSlider.vue?vue&type=style&index=2&id=243a9d68&lang=scss"
script.render = render
script.__scopeId = "data-v-243a9d68"

export default script