<template>
  <div class="admin-main-section">
    <div class="container">
      <div class="row">
        <!-- main content -->
        <div class="col-12 admin-main-section-header">
          <h3>Postavke</h3>
        </div>
        <div class="col-12">
          <!-- faq -->
          <div id="accordion">
            <div class="mb-3">
              <a
                data-toggle="collapse"
                data-target="#acc-2"
                aria-expanded="false"
                aria-controls="collapseOne"
                class="collapsed"
              >
                <h4 class="setting-header">
                  <span class="fas fa-key mr-3"></span>Postavke računa
                </h4>
              </a>
              <div
                id="acc-2"
                class="collapse show"
                aria-labelledby="heading-1"
                data-parent="#accordion"
              >
                <div class="py-3">
                  <table
                    class="table table-hover table-borderless bizInfoTable"
                  >
                    <tr>
                      <th>Korisničko ime:<span class="required">*</span></th>
                      <td class="bizInfoInput">
                        <input
                          type="text"
                          v-model="user.name"
                          placeholder="Unesite korisničko ime"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>E-adresa:<span class="required">*</span></th>
                      <td class="bizInfoInput">
                        <input
                          type="text"
                          :value="user.email"
                          disabled="true"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Trenutna lozinka:<span class="required">*</span></th>
                      <td class="bizInfoInput">
                        <input
                          type="password"
                          v-model="current_password"
                          placeholder="Unesite trenutnu lozinku"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Nova lozinka:<span class="required">*</span></th>
                      <td class="bizInfoInput">
                        <input
                          type="password"
                          v-model="password"
                          placeholder="Unesite novu lozinku"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Potvrdi novu lozinku:<span class="required">*</span>
                      </th>
                      <td class="bizInfoInput">
                        <input
                          type="password"
                          v-model="password_confirmation"
                          placeholder="Potvrdite novu lozinku"
                        />
                      </td>
                    </tr>
                  </table>

                  <button @click="save()" class="btn btn-lg btn-primary">
                    <span
                      ><span class="fas fa-check mr-2"></span> Ažuriraj
                      profil</span
                    >
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div class="mb-3">
              <a
                data-toggle="collapse"
                data-target="#acc-3"
                aria-expanded="false"
                aria-controls="collapseOne"
                class="collapsed"
              >
                <h4 class="setting-header">
                  <span class="fas fa-id-card mr-3"></span>Postavke profila
                </h4>
              </a>
              <div
                id="acc-3"
                class="collapse"
                aria-labelledby="heading-1"
                data-parent="#accordion"
              >
                <div class="py-3">
                  <table
                    class="table table-hover table-borderless bizInfoTable"
                  >
                    <tr>
                      <th>Ime i prezime:<span class="required">*</span></th>
                      <td class="bizInfoInput">
                        <input
                          type="text"
                          v-model="user.name"
                          placeholder="Unesite korisničko ime"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Kontakt broj:<span class="required">*</span></th>
                      <td class="bizInfoInput">
                        <input
                          type="number"
                          v-model="user.email"
                          placeholder="Unesite kontakt broj"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Grad:<span class="required">*</span></th>
                      <td class="bizInfoInput">
                        <input
                          type="text"
                          v-model="current_password"
                          placeholder="Unesite mjesto treniranja"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Adresa:<span class="required">*</span></th>
                      <td class="bizInfoInput">
                        <input
                          type="text"
                          v-model="password"
                          placeholder="Unesite adresu"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Slika:</th>
                      <td class="bizInfoInput">
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            :change="slika"
                          />
                          <label class="custom-file-label" for="customFile"
                            >Choose file</label
                          >
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Zanimanje:</th>
                      <td class="bizInfoInput">
                        <input
                          type="text"
                          placeholder="radno mjesto"
                          v-model="salon.facebook"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Obrazovanje:</th>
                      <td class="bizInfoInput">
                        <input
                          type="text"
                          placeholder="zvanje"
                          v-model="salon.facebook"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>O meni:</th>
                      <td class="bizInfoInput" colspan="2">
                        <!-- <input type="text" placeholder="-- click to enter salon description --" v-model="salon.description"> -->
                        <textarea
                          v-model="salon.description"
                          style="width:100%"
                          class="form-control"
                          id=""
                          cols="30"
                          rows="8"
                          maxlength="250"
                          placeholder="Predstavite ukratko svoj salon i usluge."
                        ></textarea>
                      </td>
                      <!-- <td class="bizInfoLink"></td> -->
                    </tr>
                    <tr>
                      <th>O treningu:</th>
                      <td class="bizInfoInput" colspan="2">
                        <!-- <input type="text" placeholder="-- click to enter salon description --" v-model="salon.description"> -->
                        <textarea
                          v-model="salon.description"
                          style="width:100%"
                          class="form-control"
                          id=""
                          cols="30"
                          rows="8"
                          maxlength="250"
                          placeholder="Predstavite ukratko svoj salon i usluge."
                        ></textarea>
                      </td>
                      <!-- <td class="bizInfoLink"></td> -->
                    </tr>
                    <tr>
                      <th>Facebook:</th>
                      <td class="bizInfoInput">
                        <input
                          type="text"
                          placeholder="Zalijepite link FB stranice"
                          v-model="salon.facebook"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Instagram:</th>
                      <td class="bizInfoInput">
                        <input
                          type="text"
                          placeholder="Zalijepite link IG profila"
                          v-model="salon.instagram"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Web stranica:</th>
                      <td class="bizInfoInput">
                        <input
                          type="text"
                          placeholder="Zalijepite link web stranice"
                          v-model="salon.website"
                        />
                      </td>
                    </tr>
                  </table>

                  <button @click="save()" class="btn btn-lg btn-primary">
                    <span
                      ><span class="fas fa-check mr-2"></span> Ažuriraj
                      profil</span
                    >
                  </button>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminVendorPostavke",
  components: {},
  data() {
    return {
      user: {
        name: "",
        contact: "",
      },
      password: "",
      current_password: "",
      password_confirmation: "",
      salon: {
        facebook: "",
        instagram: "",
        website: "",
        description: "",
        slika: "",
      },
    };
  },
  methods: {
    slika() {},
  },
};
</script>

<style scoped>
/* tablica za admin panel */

.bizInfoTable tr:hover {
  cursor: pointer;
}

.bizInfoTable td,
.bizInfoTable th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px transparent;
}

.bizInfoTable th {
  font-weight: bold;
  white-space: nowrap;
  width: 1%;
}

.bizInfoInput input,
.bizInfoInput select {
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: solid 1px #3d3d3d;
}

.bizInfoInput input:focus {
  border: 1px solid red;
}

.bizInfoInput span {
  display: inline-block;
  overflow-x: hidden;
  white-space: nowrap;
}

.bizInfoInput textarea {
  background: transparent;
  border: solid 1px #3d3d3d;
  margin-top: 3px;
  font-size: 0.9em;
}

.bizInfoInput textarea::placeholder {
  color: #d4d4d4;
  font-size: 1em;
}

.bizInfoCheck {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}

.lbl {
  width: 100%;
  display: flex;
  font-weight: 300;
}

.lbl input {
  width: unset;
  margin-top: 5px;
  margin-right: 6px;
  margin-left: 10px;
}

@media (max-width: 576px) {
  .bizInfoCheck {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    margin-top: 3px;
  }

  .lbl {
    width: 100%;
    display: flex;
    font-weight: 300;
    flex-flow: row;
  }

  .lbl input {
    width: unset;
    margin-top: 5px;
    margin-right: 6px;
    margin-left: 0px;
  }
}

.bizInfoLink {
  display: block;
  text-align: right;
}

@media (max-width: 575.98px) {
  .bizInfoTable tr {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
  }

  .bizInfoTable td,
  .bizInfoTable th {
    margin: 0px;
    padding: 0px;
    flex: 0 1 100%;
  }

  .bizInfoLink {
    display: none;
  }
}

.setting-header {
  color: black;
  font-weight: 500;
}

.setting-header span {
  color: #1d9ae6;
}

.setting-header:hover {
  cursor: pointer;
}

.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 10px;
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
</style>
