<template>
    <header id="naslovna" class="header">
      <div class="overlay"></div>
      <!-- <img src="images/header.jpg"> -->

      <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
        <source src="@/assets/hero-video.mp4" type="video/mp4">
      </video>

      <div class="container h-100">
        <div class="d-flex text-center h-100">
          <div class="my-auto w-100">
            <p class="bila">BR.1 APLIKACIJA ZA ORGANIZACIJU POSLOVANJA I PRONALAZAK TRENERA I KLUBOVA</p>
            <h1 class="bila font-italic">PROVEDITE VIŠE VREMENA NA TRENINGU,<br> A MANJE ZA RADNIM STOLOM!</h1>
            <div class="col-lg-10 col-xl-8 mx-auto mt-5">
              <Search/>
            </div>
          </div>
        </div>
      </div>
    </header>
</template>

<script>
import Search from '@/components/Search.vue'
export default {
  name: "HeaderHome",
  components: {
    Search
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped lang="scss"></style>