<template>
    <form class="form" method="GET" action="">
        <div class="form-group row no-gutters">
            <div class="col-sm-5">
                <select class="form-control" id="">
                    <option selected hidden>Vrsta treninga</option>
                    <option>Fitness</option>
                    <option>Crossfit</option>
                    <option>Yoga</option>
                </select>
            </div>
            <div class="col-sm-5">
                <select class="form-control" id="">
                    <option selected hidden>Lokacija</option>
                    <option>Zagreb</option>
                    <option>Split</option>
                    <option>Rijeka</option>
                    <option>Osijek</option>
                </select>
            </div>
            <div class="col-sm-2">
                <button type="submit" class="btn btn-lg btn-block btn-primary">Traži</button>
            </div>
        </div>
    </form>
</template>

<script>

export default {
    name: 'Search',
}

</script>

<style scoped lang="scss">

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #fff;
    border-bottom: 1px solid #fff;
    border-radius: 0rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn {
    height: 100%;
    text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
    
    .form-control {
        margin-bottom: 1rem;
        color: #fff;
        background-color: transparent;
        border: 1px solid transparent;
        border-bottom: 1px solid #fff;
    }
    
    .btn {
        margin-top: 1rem;
        height: 70%;
    }
}
</style>