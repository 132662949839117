<template>
  <div class="">
    <h5>Ocjene</h5>
    <div class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        id="check-ocjena-5"
      />
      <label class="custom-control-label" for="check-ocjena-5"
        ><span class="fa fa-star checked"></span>
        <span class="fa fa-star checked"></span>
        <span class="fa fa-star checked"></span>
        <span class="fa fa-star checked"></span>
        <span class="fa fa-star checked"></span> (5)
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        id="check-ocjena-4"
      />
      <label class="custom-control-label" for="check-ocjena-4"
        ><span class="fa fa-star checked"></span>
        <span class="fa fa-star checked"></span>
        <span class="fa fa-star checked"></span>
        <span class="fa fa-star checked"></span>
        <span class="fa fa-star"></span> (4)
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        id="check-ocjena-3"
      />
      <label class="custom-control-label" for="check-ocjena-3"
        ><span class="fa fa-star checked"></span>
        <span class="fa fa-star checked"></span>
        <span class="fa fa-star checked"></span>
        <span class="fa fa-star"></span>
        <span class="fa fa-star"></span> (3)
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        id="check-ocjena-2"
      />
      <label class="custom-control-label" for="check-ocjena-2"
        ><span class="fa fa-star checked"></span>
        <span class="fa fa-star checked"></span>
        <span class="fa fa-star"></span>
        <span class="fa fa-star"></span>
        <span class="fa fa-star"></span> (2)
      </label>
    </div>
    <div class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        id="check-ocjena-1"
      />
      <label class="custom-control-label" for="check-ocjena-1"
        ><span class="fa fa-star checked"></span>
        <span class="fa fa-star"></span>
        <span class="fa fa-star"></span>
        <span class="fa fa-star"></span>
        <span class="fa fa-star"></span> (1)
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarStars",
};
</script>

<style scoped lang="scss">
h5 {
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.fa-star {
  color: lightgray;
}

.checked {
  color: rgb(243, 178, 0) !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #1D9AE6;
	border-color: #cccccc;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	background-color: #1D9AE6;
	border-color: #cccccc;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.custom-control-label {
	font-weight: 400;
    font-size: .8rem;
}

.custom-control-label::before ,.custom-control-label::after {
    width:.8rem; 
    height:.8rem;
}

</style>
