<template>
  <div class="">
    <h5>Razina treninga</h5>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="razina-treninga-1"/>
      <label class="custom-control-label" for="razina-treninga-1">Početnik</label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="razina-treninga-2"/>
      <label class="custom-control-label" for="razina-treninga-2">Rekreativno</label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="razina-treninga-3"/>
      <label class="custom-control-label" for="razina-treninga-3">Pro</label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="razina-treninga-4"/>
      <label class="custom-control-label" for="razina-treninga-4">Veteran</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarFilter",
};
</script>

<style scoped lang="scss">
h5 {
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.fa-star {
  color: lightgray;
}

.checked {
  color: rgb(243, 178, 0) !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #1D9AE6;
	border-color: #cccccc;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	background-color: #1D9AE6;
	border-color: #cccccc;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.custom-control-label {
	font-weight: 400;
    font-size: .8rem;
}

.custom-control-label::before ,.custom-control-label::after {
    width:.8rem; 
    height:.8rem;
}

</style>
